.container {
  font-family: var(--font-primary);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-body);
  color: var(--color-link);
  text-align: left;
  text-decoration: none;
}

.container:hover {
  color: var(--color-link-hover);
}

.container:active {
  color: var(--color-link-onclick);
}

/* Font Size Variations */
.h2 {
  font-size: var(--font-size-h2);
}
